<template>
  <div class="container" v-if="vod">
    <Header />
    <div class="content">
      <div class="top">
        <div class="vodImgBox">
          <img
            class="vodImg"
            :src="vod.vodPic"
          />
        </div>
        <div class="intro">
          <div class="intro-item">
            <p class="vodName">{{ vod.vodName }}</p>
          </div>
          <div class="intro-item">
            <p class="title">导演：</p>
            <p class="text">
              {{ vod.vodDirector }}
            </p>
          </div>
          <div class="intro-item">
            <p class="title">演员：</p>
            <p class="text">
              {{ vod.vodActor }}
            </p>
          </div>
          <div class="intro-item">
            <p class="title">类型：</p>
            <p class="text">{{ vod.vodTag }}</p>
          </div>
          <div class="intro-item">
            <p class="title">更新时间：</p>
            <n-time :time="Date.parse(new Date(vod.vodTime))" format="yyyy-MM-dd hh:mm" style="color: #e0e2e3;"/>
          </div>
          <div class="intro-item">
            <p class="title">更新状态：</p>
            <p>{{ vod.vodRemarks }}</p>
          </div>

          <!-- 播放按钮 -->
          <div class="play">
            <button @click="play">
              <img src="@/assets/img/searchDetail/play.png" />
              <p>播放</p>
            </button>
          </div>
        </div>
      </div>
      <div class="synopsis">
        <div>
          <div class="synopsis-title">剧情介绍</div>
        </div>
        <div class="synopsis-text">
          <span v-html="vod.vodContent"></span>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { getCurrentInstance, defineProps, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

const props = defineProps(["vodId"]);

const router = useRouter();
const route = useRoute();

const currentInstance = getCurrentInstance();
const { $axios } = currentInstance.appContext.config.globalProperties;

const vodId = ref(props.vodId);
let vod = ref(null);

onMounted(() => {
  getVodDetail();
});

function play(){
  router.push({ name: "Player", params: { vodId: vodId.value ,anthology:1} });
}


async function getVodDetail() {
  await $axios
    .get("/vod/vodDetail/" + vodId.value, {})
    .then((res) => {
      let data = res.data.data;
      vod.value = data;
    })
    .catch((err) => {
      console.log(err);
    });
}
</script>

<style scoped>
.content {
  margin: 0 10% 0 10%;
  height: 100vh;
}
.top {
  display: flex;
  flex-direction: row;
  height: 400px;
  padding: 50px;
}
.vodImgBox {
  height: 100%;
  width: 25%;
}
.vodImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.6s; /*设置动画执行的时间为0.6s */
  cursor: pointer;
}
.intro {
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 100%;
  position: relative;
}
.intro-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: none;
  padding: 10px;
}
.intro-item .vodName {
  color: #ffffff;
  font-weight: bold;
  font-size: 25px;
  margin: 0 0 0 20px;
}
.intro-item .title {
  font-weight: bold;
  color: #ff0000;
  margin: 0 0 0 20px;
  white-space: nowrap;
}
.intro-item p {
  margin: 0 0 0 0;
  color: #e0e2e3;
}
.intro-item .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.play {
  position: absolute;
  left: 20px;
  bottom: 0;
}
.play button {
  border: none;
  background-color: red;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}
.play button:hover {
  background-color: rgb(163, 10, 10);
}
.play img {
  width: 25px;
  height: 25px;
}
.play p {
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
}
.synopsis{
  margin-top: 10px;
}
.synopsis-title {
  background-color: red;
  width: 100px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
}
.synopsis-text{
  color: #e0e2e3;
}
@media screen and (max-width: 480px) {
  .content {
    margin: 0 10px 0 10px;
  }
  .top {
    height: 320px;
    padding: 0;
    padding-top: 20px;
  }
  .vodImgBox {
    width: 40%;
  }
  .intro {
    width: 55%;
  }
  .intro .vodName {
    font-size: 15px;
    margin: 0 0 0 4px;
  }
  .intro-item .title {
    margin: 0 0 0 4px;
  }
}
</style>