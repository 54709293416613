<template>
  <div class="videoList">
    <div class="title">
      <div class="title-left">
        <p>{{ props.title }}</p>
      </div>
      <div class="title-right">
        <n-button
          @click="moreClick(props.typeName)"
          size="small"
          color="red"
          style="font-size: 1.6vh"
        >
          更多>
        </n-button>
      </div>
    </div>
    <div class="list">
      <n-grid
        :cols="reduceColumn ? 3 : 6"
        responsive="self"
        :x-gap="reduceColumn ? 10 : 20"
        :y-gap="reduceColumn ? 10 : 25"
        :collapsed-rows="3"
        :collapsed="true"
        item-responsive
      >
        <n-gi class="vodItem" v-for="(item, index) in hotVods" :key="index">
          <div class="vodImgBox" @click="imgClick(item.vodId)">
            <img class="vodImg" :src="item.vodPic" />
            <p class="vodRemarks">{{ item.vodRemarks }}</p>
          </div>
          <div class="vodName">
            <p>{{ item.vodName }}</p>
          </div>
        </n-gi>
      </n-grid>
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, defineProps, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
const props = defineProps(["title", "typeName", "area", "other"]);
/**
 * 通过getCurrentInstance方法获取当前实例
 * 再根据当前实例找到全局实例对象appContext，进而拿到全局实例的config.globalProperties。
 */
const currentInstance = getCurrentInstance();
const { $axios } = currentInstance.appContext.config.globalProperties;

const router = useRouter();
const route = useRoute();

//响应式视频列数
const reduceColumn = ref(window.innerWidth < 600);

window.addEventListener("resize", () => {
  reduceColumn.value = window.innerWidth < 600;
});

let hotVods = ref([]);
onMounted(() => {
  switch (props.typeName) {
    case "电影":
      getHotVods("片");
      break;
    case "电视剧":
      getHotVods("剧");
      break;
    default:
      getHotVods(props.typeName);
  }
});

//更多按钮点击事件
function moreClick(typeName) {
  switch (props.typeName) {
    case "电影":
      router.push({
        name: "more",
        params: {
          pTypeIndex: 1,
          area: props.area ? props.area : "",
          other: props.other ? props.other : "",
        },
      });
      break;
    case "电视剧":
      router.push({
        name: "more",
        params: {
          pTypeIndex: 2,
          area: props.area ? props.area : "",
          other: props.other ? props.other : "",
        },
      });
      break;
    case "动漫":
      router.push({
        name: "more",
        params: {
          pTypeIndex: 4,
          area: props.area ? props.area : "",
          other: props.other ? props.other : "",
        },
      });
      break;
    case "综艺":
      router.push({
        name: "more",
        params: {
          pTypeIndex: 3,
          area: props.area ? props.area : "",
          other: props.other ? props.other : "",
        },
      });
      break;
  }
}

//根据类型名请求获取视频信息
async function getHotVods(type) {
  await $axios
    .get("/vod/hotVodMonthTypeAreaWithHitsMonth", {
      params: {
        typeName: type,
        area: props.area,
        size: 18,
      },
    })
    .then((res) => {
      hotVods.value = res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

//图片点击事件
function imgClick(vodId) {
  // const href = router.resolve({
  //   //使用resolve
  //   name: "Player", //这里是跳转页面的name
  //   params: { vodId, anthology: 1 },
  // });
  // window.open(href.href, "_blank");

  router.push({ name: "VodDetail", params: { vodId: vodId } });
}
</script>

<style scoped>
.videoList {
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.title {
  display: flex;
  flex-direction: row;
  height: auto;
  color: #faf8f8;
}
.title-left {
  width: 50%;
  text-align: left;
  font-size: 2vh;
  font-weight: bold;
}
.title-right {
  width: 50%;
  text-align: right;
  align-self: center;
}
.vodImgBox {
  position: relative;
  height: 80%;
  width: 100%;
}
.vodImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.6s; /*设置动画执行的时间为0.6s */
  cursor: pointer;
}
.vodImg:hover {
  transform: scale(1.2); /*设置图片按照比例放大1.3倍 */
}
.vodRemarks {
  position: absolute; /* 让文本绝对定位 */
  bottom: 0; /* 将文本定位到容器的底部 */
  right: 0; /* 将文本定位到容器的右边 */
  margin: 10px; /* 可以添加一些边距，以调整文本与容器边缘的距离 */
  color: #ffffff;
  font-weight: bold;
  font-size: 0.9rem;
}
.vodName {
  width: 100%;
  display: block;
  overflow: hidden;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
</style>