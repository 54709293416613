<template>
  <n-notification-provider>
    <n-message-provider>
      <router-view :key="$route.fullPath"></router-view>
    </n-message-provider>
  </n-notification-provider>
</template>

<script setup>
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #111319;
}
// 隐藏滚动条
::-webkit-scrollbar {
  display: none;
}
</style>
