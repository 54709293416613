<template>
  <div class="container">
    <div class="title">
      <div class="title-left">
        <p>猜你喜欢</p>
      </div>
      <div class="title-right">
        <p>更多></p>
      </div>
    </div>
    <div class="list">
      <n-grid cols="2 400:4 600:6" x-gap="20" y-gap="30">
        <n-grid-item
          class="vodItem"
          v-for="(item, index) in hotVods"
          :key="index"
        >
          <div class="vodImgBox" @click="imgClick(item.vodId)">
            <img class="vodImg" :src="item.vodPic" />
            <p class="vodRemarks">{{ item.vodRemarks }}</p>
          </div>
          <div class="vodName">
            <p>{{ item.vodName }}</p>
          </div>
        </n-grid-item>
      </n-grid>
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, toRef, defineProps } from "vue";
import { useRouter, useRoute } from "vue-router";
const props = defineProps(["vodClass", "area", "tag"]);

const currentInstance = getCurrentInstance();
const { $axios } = currentInstance.appContext.config.globalProperties;

const router = useRouter();
const route = useRoute();

let hotVods = toRef([]);
onMounted(() => {
  setTimeout(() => {
    getGuessYouLike(props.vodClass, props.area, props.tag);
  }, 2000);
});
//获取猜你喜欢的视频信息(相似类，相同地域，相似标签，较热门)
async function getGuessYouLike(vodClass, area, tag) {
  await $axios
    .get("/vod/getGuessYouLike", {
      params: {
        class: vodClass,
        area: area,
        tag: tag,
      },
    })
    .then((res) => {
      hotVods.value = res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

function imgClick(vodId) {
  // const href = router.resolve({
  //   //使用resolve
  //   name: "Player", //这里是跳转页面的name
  //   params: { vodId ,anthology:1},
  // });
  // window.open(href.href, "_blank");

  router.push({ name: "VodDetail", params: { vodId: vodId } });
}
</script>

<style scoped>
.title {
  display: flex;
  flex-direction: row;
  height: auto;
  color: #faf8f8;
}
.title-left {
  width: 50%;
  text-align: left;
  font-weight: bold;
}
.title-right {
  width: 50%;
  text-align: right;
  align-self: center;
}
.title-right p:hover {
  color: #f90202;
}
.vodImgBox {
  position: relative;
  height: 80%;
  width: 100%;
}
.vodImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.6s; /*设置动画执行的时间为0.6s */
  cursor: pointer;
}
.vodImg:hover {
  transform: scale(1.2); /*设置图片按照比例放大1.3倍 */
}
.vodRemarks {
  position: absolute; /* 让文本绝对定位 */
  bottom: 0; /* 将文本定位到容器的底部 */
  right: 0; /* 将文本定位到容器的右边 */
  margin: 10px; /* 可以添加一些边距，以调整文本与容器边缘的距离 */
  color: #ffffff;
  font-weight: bold;
  font-size: 0.8rem;
}
.vodName {
  width: 100%;
  display: block;
  overflow: hidden;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
</style>