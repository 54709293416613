<template>
  <div class="container">
    <Header title="更新表" />
    <div class="top">
      <div class="type">
        <n-tabs
          class="typeTab"
          type="bar"
          size="medium"
          @update:value="typeUpdate"
        >
          <n-tab
            v-for="(item, index) in typeList"
            :key="index"
            :name="item.name"
          >
            {{ item.name }}
          </n-tab>
        </n-tabs>
      </div>
      <div class="week">
        <n-tabs
          class="weekTab"
          type="bar"
          size="medium"
          :default-value="week"
          @update:value="weekUpdate"
        >
          <n-tab
            v-for="(item, index) in weekList"
            :key="index"
            :name="item.name"
          >
            {{ item.name }}
          </n-tab>
        </n-tabs>
      </div>
    </div>
    <div class="content">
      <!-- 视频区 -->
      <div class="vodList">
        <n-grid
          cols="2 s:3 m:4 l:5 xl:6 2xl:7"
          responsive="screen"
          x-gap="20"
          y-gap="30"
        >
          <n-grid-item
            class="vodItem"
            v-for="(item, index) in vodList"
            :key="index"
          >
            <div class="vodImgBox" @click="imgClick(item.vodId)">
              <img class="vodImg" :src="item.vodPic" />
              <p class="vodRemarks">{{ item.vodRemarks }}</p>
            </div>
            <div class="vodName">
              <p>{{ item.vodName }}</p>
            </div>
          </n-grid-item>
        </n-grid>
      </div>
      <div class="tip" v-if="vodList.length === 0">{{ tip }}</div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { useRouter, useRoute } from "vue-router";
import { ref, getCurrentInstance, onMounted } from "vue";

const router = useRouter();
const route = useRoute();

const currentInstance = getCurrentInstance();
const { $axios } = currentInstance.appContext.config.globalProperties;

let weekday = [
  "星期日",
  "星期一",
  "星期二",
  "星期三",
  "星期四",
  "星期五",
  "星期六",
];

let vodList = ref([]);
let typeName = ref("动漫");
let area = ref("中国");
let week = ref(weekday[new Date().getDay()]);
let tip = ref("正在加载...");

let typeList = ref([
  {
    name: "国漫",
  },
  {
    name: "日漫",
  },
  {
    name: "电影",
  },
  {
    name: "电视剧",
  },
  {
    name: "综艺",
  },
]);
let weekList = ref([
  {
    name: "星期日",
  },
  {
    name: "星期一",
  },
  {
    name: "星期二",
  },
  {
    name: "星期三",
  },
  {
    name: "星期四",
  },
  {
    name: "星期五",
  },
  {
    name: "星期六",
  },
]);

onMounted(() => {
  getVods();
});

function typeUpdate(typeName1) {
  switch (typeName1) {
    case "国漫":
      typeName.value = "动漫";
      area.value = "中国";
      break;
    case "日漫":
      typeName.value = "动漫";
      area.value = "日本";
      break;
    case "电影":
      typeName.value = "片";
      area.value = "";
      break;
    case "电视剧":
      typeName.value = "剧";
      area.value = "";
      break;
    case "综艺":
      typeName.value = "综艺";
      area.value = "";
      break;
    default:
      break;
  }
  vodList.value = [];
  getVods();
}
function weekUpdate(week1) {
  week.value = week1;
  vodList.value = [];
  getVods();
}

function imgClick(vodId) {
  // const href = router.resolve({
  //   //使用resolve
  //   name: "Player", //这里是跳转页面的name
  //   params: { vodId, anthology: 1 },
  // });
  // window.open(href.href, "_blank");
  
  router.push({ name: "VodDetail", params: { vodId: vodId } });
}

//========================================
async function getVods() {
  await $axios
    .get("/vod/getVodsByWeekType", {
      params: {
        week: week.value,
        type: typeName.value,
        area: area.value,
      },
    })
    .then((res) => {
      vodList.value = res.data.data;
      if (vodList.value.length === 0) {
        tip.value = "没有更多了";
      } else {
        tip.value = "正在加载...";
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
</script>

<style scoped>
.content {
  margin: 0 10% 0 10%;
}
.top {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.typeTab {
  font-size: 100px;
  padding: 10px;
}
.top >>> .n-tabs-tab {
  color: #ffffff !important;
  font-size: 1.9vh;
  font-weight: bold;
}
.top >>> .n-tabs-tab--active .n-tabs-tab__label {
  color: #ff3232;
  font-weight: bold;
}
.top >>> .n-tabs-tab__label:hover {
  color: #ff3232 !important;
  font-weight: bold;
}
.top >>> .n-tabs-bar {
  background-color: #ff3232 !important;
  font-weight: bold;
}
.vodList {
  margin-top: 30px;
}
.vodImgBox {
  position: relative;
  height: 80%;
  width: 100%;
}
.vodImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.6s; /*设置动画执行的时间为0.6s */
  cursor: pointer;
}
.vodImg:hover {
  transform: scale(1.2); /*设置图片按照比例放大1.3倍 */
}
.vodRemarks {
  position: absolute; /* 让文本绝对定位 */
  bottom: 0; /* 将文本定位到容器的底部 */
  right: 0; /* 将文本定位到容器的右边 */
  margin: 10px; /* 可以添加一些边距，以调整文本与容器边缘的距离 */
  color: #ffffff;
  font-weight: bold;
  font-size: 0.9rem;
}
.vodName {
  width: 100%;
  display: block;
  overflow: hidden;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.tip {
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}
@media screen and (max-width: 450px) {
  .weekTab {
    width: 99vw;
  }
}
</style>