<template>
  <div class="container">
    <Header :title="title"></Header>
    <div class="content" ref="wrapper" @scroll="handleScroll">
      <!-- 分类选择 -->
      <div class="vodClass">
        <n-tabs
          class="vodType"
          :default-value="pTypeIndex"
          type="bar"
          size="medium"
          v-if="vodTypeList.length > 0"
          @update:value="pTypeUpdate"
        >
          <n-tab
            v-for="(item, index) in vodTypeList"
            :key="index"
            :name="index"
          >
            {{ item.name }}
          </n-tab>
        </n-tabs>
        <!-- sonType -->
        <n-tabs
          class="vodType"
          v-if="vodSonTypeList.length > 0"
          @update:value="sTypeUpdate"
          type="bar"
          size="medium"
        >
          <n-tab
            v-for="(item, index) in vodSonTypeList"
            :key="index"
            :name="index"
          >
            {{ item.name }}
          </n-tab>
        </n-tabs>
        <!-- 地域 -->
        <n-tabs
          class="vodType"
          :default-value="area.length > 0 ? area : '全部地区'"
          v-if="areaList.length > 0"
          @update:value="areaTypeUpdate"
          type="bar"
          size="medium"
        >
          <n-tab
            v-for="(item, index) in areaList"
            :key="index"
            :name="item.name"
          >
            {{ item.name }}
          </n-tab>
        </n-tabs>
        <!-- other -->
        <n-tabs
          class="vodType"
          v-if="otherList.length > 0"
          :default-value="other.length > 0 ? other : '最新'"
          @update:value="otherTypeUpdate"
          type="bar"
          size="medium"
        >
          <n-tab
            v-for="(item, index) in otherList"
            :key="index"
            :name="item.name"
          >
            {{ item.name }}
          </n-tab>
        </n-tabs>
      </div>
      <!-- 视频区 -->
      <div class="vodList">
        <n-grid
          cols="2 s:3 m:4 l:5 xl:6 2xl:7"
          responsive="screen"
          x-gap="20"
          y-gap="30"
        >
          <n-grid-item
            class="vodItem"
            v-for="(item, index) in vodList"
            :key="index"
          >
            <div class="vodImgBox" @click="imgClick(item.vodId)">
              <img class="vodImg" :src="item.vodPic" />
              <p class="vodRemarks">{{ item.vodRemarks }}</p>
            </div>
            <div class="vodName">
              <p>{{ item.vodName }}</p>
            </div>
          </n-grid-item>
        </n-grid>
      </div>
      <!-- 加载更多 -->
      <div class="loadMore">
        <p class="loadMoreText">{{ loadMoreText }}</p>
      </div>
      <n-back-top :right="50" />
    </div>
    <Footer />
  </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { onMounted, getCurrentInstance, ref, defineProps } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

const props = defineProps(["pTypeIndex", "title", "area", "other"]);

const store = useStore();

const router = useRouter();
const route = useRoute();

const currentInstance = getCurrentInstance();
const { $axios } = currentInstance.appContext.config.globalProperties;

let vodTypeList = ref([]);
let vodSonTypeList = ref([]);
let areaList = ref([
  {
    name: "全部地区",
  },
  {
    name: "中国",
  },
  {
    name: "日本",
  },

  {
    name: "美国",
  },
  {
    name: "英国",
  },
  {
    name: "韩国",
  },
  {
    name: "泰国",
  },
]);
let otherList = ref([
  {
    name: "最新",
  },
  {
    name: "最热",
  },
  {
    name: "高分",
  },
]);
let vodList = ref([]);
let pTypeId = ref(0);
let sTypeId = ref(0);
let area = ref(props.area ? props.area : "");
let other = ref(props.other ? props.other : "最新");
let totalPage = ref(0);
let page = ref(1);
let size = ref(39);
let loadMoreText = ref("加载更多...");
let title = ref(props.title ? props.title : "分类");

let pTypeIndex = ref(props.pTypeIndex ? props.pTypeIndex : 0); //父类型的默认选中下标

onMounted(() => {
  getVodAllType();
  setTimeout(() => {
    if (props.pTypeIndex != 0 && props.pTypeIndex) {
      pTypeUpdate(props.pTypeIndex);
    } else {
      getVods();
    }
    if (props.area && props.area.length > 0) {
      areaTypeUpdate(props.area);
    }
    if (props.other && props.other.length > 0) {
      otherTypeUpdate(props.other);
    }
  }, 2000);
});

//加载更多
const wrapper = ref(null);
function handleScroll() {
  if (
    wrapper.value.scrollTop + wrapper.value.clientHeight >=
    wrapper.value.scrollHeight - 1
  ) {
    if (page.value >= totalPage.value) {
      loadMoreText = "没有更多了";
      return;
    }
    loadMoreText.value = "正在加载...";
    page.value = ++page.value;
    getVods();
    setTimeout(() => {
      if (page.value >= totalPage.value) loadMoreText = "没有更多了";
      else loadMoreText.value = "正在加载...";
    }, 2000);
  }
}

//index是数组的下标
function pTypeUpdate(index) {
  let pVodType;
  pVodType = vodTypeList.value[index];
  pTypeId.value = pVodType.typeId;
  if (pTypeId.value === 0) {
    vodSonTypeList.value = [];
  } else {
    vodSonTypeList.value = pVodType.sonTypeList;
  }
  sTypeId.value = 0;
  page.value = 1;
  vodList.value = [];
  getVods();
}

function sTypeUpdate(index) {
  let sVodType;
  sVodType = vodSonTypeList.value[index];
  pTypeId.value = sVodType.typeId;
  page.value = 1;
  vodList.value = [];
  getVods();
}

function areaTypeUpdate(name) {
  if (name === "全部地区") {
    area.value = "";
  } else {
    area.value = name;
  }
  page.value = 1;
  vodList.value = [];
  getVods();
}

function otherTypeUpdate(name) {
  other.value = name;
  page.value = 1;
  vodList.value = [];
  getVods();
}

function imgClick(vodId) {
  // const href = router.resolve({
  //   //使用resolve
  //   name: "Player", //这里是跳转页面的name
  //   params: { vodId ,anthology:1},
  // });
  // window.open(href.href, "_blank");

  router.push({ name: "VodDetail", params: { vodId: vodId } });
}

// ========================下面是请求的==============
async function getVods() {
  await $axios
    .get("/vod/vods/", {
      params: {
        pTypeId: pTypeId.value,
        sTypeId: sTypeId.value,
        area: area.value,
        other: other.value,
        current: page.value,
        size: size.value,
      },
    })
    .then((res) => {
      let data = res.data.data;
      totalPage.value = data.pages;
      if (vodList.value.length > 0) {
        var list = data.records[0].vodSimpleVos;
        for (var i = 0; i < list.length; i++) {
          vodList.value.push(list[i]);
        }
      } else {
        vodList.value = data.records[0].vodSimpleVos;
      }
    })
    .catch((err) => {});
}

async function getVodAllType() {
  await $axios
    .get("/vodType/vodAllType/", {})
    .then((res) => {
      const data = res.data.data;
      var plist = [];
      for (var i = 0; i < data.length; i++) {
        var parent = data[i];
        var slist = [];
        var son = parent.typeSons;
        for (var j = 0; j < son.length; j++) {
          slist.push({
            typeId: son[j].typeId,
            name: son[j].typeName,
          });
        }
        if (son.length != 0) {
          slist.unshift({
            typeId: 0,
            name: "全部类型",
          });
        }
        plist[i] = {
          typeId: parent.typeId,
          name: parent.typeName,
          sonTypeList: slist,
        };
      }
      plist.unshift({
        typeId: 0,
        name: "全部类型",
        typeSonList: [],
      });
      vodTypeList.value = plist;
    })
    .catch((err) => {
      console.log(err);
    });
}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  margin: 30px 10% 0 10%;
  height: 100vh;
  overflow-y: scroll;
}
.vodType {
  margin-top: 10px;
}
.vodType >>> .n-tabs-tab {
  color: #ffffff !important;
  font-size: 1.9vh;
  font-weight: bold;
}
.vodType >>> .n-tabs-tab--active .n-tabs-tab__label {
  color: #ff3232;
  font-weight: bold;
}
.vodType >>> .n-tabs-tab__label:hover {
  color: #ff3232 !important;
  font-weight: bold;
}
.vodType >>> .n-tabs-bar {
  background-color: #ff3232 !important;
  font-weight: bold;
}
.vodList {
  margin-top: 30px;
}
.vodImgBox {
  position: relative;
  height: 80%;
  width: 100%;
}
.vodImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.6s; /*设置动画执行的时间为0.6s */
  cursor: pointer;
}
.vodImg:hover {
  transform: scale(1.2); /*设置图片按照比例放大1.3倍 */
}
.vodRemarks {
  position: absolute; /* 让文本绝对定位 */
  bottom: 0; /* 将文本定位到容器的底部 */
  right: 0; /* 将文本定位到容器的右边 */
  margin: 10px; /* 可以添加一些边距，以调整文本与容器边缘的距离 */
  color: #ffffff;
  font-weight: bold;
  font-size: 0.9rem;
}
.vodName {
  width: 100%;
  display: block;
  overflow: hidden;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.loadMore {
  width: 100%;
  text-align: center;
}
.loadMoreText {
  color: #ffffff;
}
</style>