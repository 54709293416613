<template>
  <div class="container">
    <div class="title">
      <div class="vodName">
        <p style="font-size: 1.2rem; font-weight: bold; color: #ffffff">
          {{ vod.vodName }}
        </p>
      </div>
      <div class="vodIntro" style="color: #ffffff">
        {{ vod.vodYear }}·{{ vod.vodArea }}·{{ vod.vodClass }}
      </div>
      <div class="introductory">
        <p
          @click="introClick"
          style="
            color: #ffffff;
            background-color: #1e2126;
            width: 50px;
            text-align: center;
            border-radius: 10px;
          "
        >
          简介>
        </p>
      </div>
    </div>
    <!-- 功能栏 -->
    <div class="controlStrip">
      <n-grid x-gap="12" :cols="4">
        <n-gi>
          <div class="">
            <button @click="pre">上一集</button>
          </div>
        </n-gi>
        <n-gi>
          <div class="">
            <button @click="next">下一集</button>
          </div>
        </n-gi>
        <n-gi>
          <div class="">
            <button @click="refresh">刷新</button>
          </div>
        </n-gi>
        <n-gi>
          <div class=""></div>
        </n-gi>
      </n-grid>
    </div>
    <!-- ========== -->
    <div class="anthology">
      <div>
        <p style="font-weight: bold; color: #ffffff">资源列表</p>
      </div>
      <div class="anthologyBox">
        <div
          class="anthologyItem"
          v-for="(item, index) in vodUrls"
          :key="index"
          :style="{
            color: anthologyListIndex === index ? '#ff0000' : '#fff',
          }"
          @click="anthology(item, index)"
        >
          <p>第{{ index + 1 }}集</p>
        </div>
      </div>
    </div>
    <!-- ================== -->
    <!-- 猜你喜欢 -->
    <div>
      <GuessYouLike :vodClass="vodClass" :area="area" :tag="tag"></GuessYouLike>
    </div>
  </div>
</template>

<script setup>
import {
  getCurrentInstance,
  onMounted,
  ref,
  toRef,
  defineProps,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import GuessYouLike from "@/components/GuessYouLike.vue";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const currentInstance = getCurrentInstance();
const { $axios } = currentInstance.appContext.config.globalProperties;

const props = defineProps(["vodId", "anthology"]);
const store = useStore();

let vodId = ref(0);
let vod = toRef({}); //视频详细信息
let vodUrls = toRef([]); //播放地址集合
let anthologyListIndex = ref(0); //当前集的坐标
let vodClass = ref("");
let area = ref("");
let tag = ref("");
let url = ref("");
onMounted(() => {
  //刷新后自动选集
  setTimeout(() => {
    let currentVodData = JSON.parse(
      localStorage.getItem(props.vodId + "_VodData")
    );
    if (
      Object.keys(currentVodData).length != 0 &&
      currentVodData.vodId != vodId.value
    ) {
      return;
    }
    anthologyListIndex.value = currentVodData.currentAnthologyListIndex;
    anthology(
      vodUrls.value[anthologyListIndex.value],
      anthologyListIndex.value
    );
  }, 1500);
});

//跳转视频介绍
function introClick() {
  const href = router.resolve({
    //使用resolve
    name: "VodDetail", //这里是跳转页面的name
    params: { vodId: vodId.value },
  });
  window.open(href.href, "_blank");
}
//下一集
function next() {
  if (anthologyListIndex.value >= vodUrls.value.length - 1) {
    return;
  }
  anthologyListIndex.value = ++anthologyListIndex.value;
  anthology(vodUrls.value[anthologyListIndex.value], anthologyListIndex.value);
}
//上一集
function pre() {
  if (anthologyListIndex.value <= 0) {
    return;
  }
  anthologyListIndex.value = --anthologyListIndex.value;
  anthology(vodUrls.value[anthologyListIndex.value], anthologyListIndex.value);
}
//刷新
function refresh() {
  location.reload();
}

//监听父组件传来的vodId
watchEffect(() => {
  setTimeout(() => {
    vodId.value = props.vodId;
    getVodDetail(props.vodId);
    //初始化当前集
    if (props.anthology) {
      anthologyListIndex.value = Number(props.anthology) - 1;
    }
  }, 1000);
});

//根据视频id请求获取视频信息
async function getVodDetail(vodId) {
  await $axios
    .get("/vod/vodDetail/" + vodId, {})
    .then((res) => {
      vod.value = res.data.data;
      vodUrls.value = res.data.data.m3u8Urls;
      vodClass.value = vod.value.vodClass;
      area.value = vod.value.vodArea;
      tag.value = vod.value.vodTag;
      url.value = vodUrls.value[anthologyListIndex.value];
      //将当前视频地址存入store
      store.commit("changeCurrentVodUrl", url.value);
      //观看历史，将当前视频数据存入本地
      let history = JSON.parse(localStorage.getItem("vodHistory"))
        ? JSON.parse(localStorage.getItem("vodHistory"))
        : [];
      for (let i = 0; i < history.length; i++) {
        if (String(history[i].vodId) === String(vodId)) {
          history.splice(i, 1); //删除数组中的该数据
        }
      }
      let vodData = {
        vodId: vodId,
        vod: vod.value,
        anthology: anthologyListIndex.value + 1,
        time: new Date(),
      };
      //将新数据存入
      history.unshift(vodData);
      localStorage.setItem("vodHistory", JSON.stringify(history));
    })
    .catch((err) => {
      console.log(err);
    });
}
//选集
function anthology(url2, index) {
  anthologyListIndex.value = index;
  url.value = url2;
  //将当前视频地址存入store
  store.commit("changeCurrentVodUrl", url.value);
  //将当前集坐标和视频id存入本地缓存
  localStorage.setItem(
    props.vodId + "_VodData",
    JSON.stringify({ vodId: vodId.value, currentAnthologyListIndex: index })
  );
  //观看历史，将当前视频数据存入本地
  let history = JSON.parse(localStorage.getItem("vodHistory"))
    ? JSON.parse(localStorage.getItem("vodHistory"))
    : [];
  for (let i = 0; i < history.length; i++) {
    if (String(history[i].vodId) === String(vodId.value)) {
      history.splice(i, 1); //删除数组中的该数据
    }
  }
  let vodData = {
    vodId: vodId.value,
    vod: vod.value,
    anthology: anthologyListIndex.value + 1,
    time: new Date(),
  };
  //将新数据存入
  history.unshift(vodData);
  localStorage.setItem("vodHistory", JSON.stringify(history));
}
</script>

<style scoped>
button {
  border: none;
}
.container {
  display: flex;
  flex-direction: column;
}
.title {
  width: 100%;
  height: 30%;
}
.controlStrip {
  display: flex;
  flex-direction: row;
  background-color: #1b1a25;
  border-radius: 3px;
}
.controlStrip button {
  background-color: rgb(0, 0, 0, 0);
  color: #999999;
  padding: 10px;
  border: 1px solid #202125;
  border-radius: 10px;
}
.controlStrip button:hover {
  color: #000;
}
.anthology {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.anthologyBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.anthologyItem {
  /*height: auto;
  max-width: 90px;*/
  height: 49px;
  width: 81px;
  background-color: #1e2126;
  text-align: center;
  border-radius: 10px;
  margin: 5px;
}
</style>