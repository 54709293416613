<template>
  <div class="container">
    <!-- 搜索弹框 -->
    <n-drawer
      class="searchDrawer"
      :show="searchActive"
      :width="drawerWidth"
      :closable="true"
      @mask-click="maskClick"
      @after-enter="afterEnter"
      placement="right"
      drawer-style="background-color: #111319;"
    >
      <n-drawer-content>
        <div class="drawerContainer">
          <div class="searchHeader">
            <div class="searchInput">
              <n-dropdown
                class="dropdown"
                :show="searchItems.length > 0"
                :options="searchItems"
                label-field="vodName"
                key-field="vodName"
                @select="searchItemClick"
                style="width: 300px"
              >
                <input
                  type="text"
                  @input="handleInputChange"
                  v-model="searchText"
                  placeholder="剧集/电影/动漫/综艺..."
                />
              </n-dropdown>
              <button @click="searchClick">
                <img src="@/assets/img/header/searchWhite.png" />
              </button>
            </div>
          </div>
          <div class="searchBody">
            <div class="searchHistory">
              <div class="searchHistory-top">
                <div class="searchHistory-top-left">
                  <p>搜索历史</p>
                </div>
                <div class="searchHistory-top-right">
                  <p @click="deleteSearchHistory">删除记录</p>
                </div>
              </div>
              <div class="searchHistory-body">
                <div
                  class="searchHistoryItem"
                  v-for="(item, index) in SearchHistoryList"
                  :key="index"
                  @click="clickSearchHistoryItem(item)"
                >
                  <p>{{ item }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </n-drawer-content>
    </n-drawer>
    <!-- end -->

    <!-- 观看历史弹框 -->
    <n-drawer
      class="historyDrawer"
      :show="historyActive"
      :width="drawerWidth"
      :closable="true"
      @mask-click="historyMaskClick"
      @after-enter="historyAfterEnter"
      placement="right"
      drawer-style="background-color: #111319;"
    >
      <n-drawer-content>
        <div class="drawerContainer">
          <div class="clearHistory">
            <button @click="removeHistory">清除观看历史</button>
          </div>
          <div class="historyContent">
            <div
              class="historyItem"
              v-for="(item, index) in historyVodData"
              :key="index"
            >
              <div
                class="imgBox"
                @click="historyImgClick(item.vodId, item.anthology)"
              >
                <img :src="item.vod.vodPic" />
              </div>
              <div class="historyItemIntro">
                <div class="vodName">
                  <n-ellipsis :line-clamp="1">
                    {{ item.vod.vodName }}
                  </n-ellipsis>
                </div>
                <div class="anthology">
                  <p>第{{ item.anthology }}集</p>
                </div>
                <div class="time">
                  <p style="font-size: 10px">{{ item.time }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </n-drawer-content>
    </n-drawer>
    <!-- end -->

    <!-- 登录弹窗 -->
    <n-modal
      v-model:show="showLoginModal"
      preset="card"
      :style="loginBodyStyle"
      size="huge"
      :bordered="false"
      :key="showLoginModalKey"
    >
      <n-tabs
        :default-value="tabsValue"
        size="large"
        justify-content="space-evenly"
      >
        <n-tab-pane name="signin" tab="登录">
          <n-form>
            <n-form-item-row label="账号">
              <n-input
                placeholder="请输入账号或邮箱"
                v-model:value="id"
                :status="loginIdStatus"
              />
            </n-form-item-row>
            <n-form-item-row label="密码">
              <n-input
                type="password"
                show-password-on="mousedown"
                placeholder="请输入密码"
                :maxlength="20"
                v-model:value="password"
                :status="loginPasswordStatus"
              />
            </n-form-item-row>
          </n-form>
          <n-button type="primary" block secondary strong @click="login">
            登录
          </n-button>
          <div
            style="
              cursor: pointer;
              text-align: right;
              margin-top: 5px;
              color: #8c8888;
            "
            @click="showResetPasswordModal = !showResetPasswordModal"
          >
            忘记密码
          </div>
        </n-tab-pane>
        <n-tab-pane name="signup" tab="注册">
          <n-form>
            <n-form-item-row
              label="账号"
              :validation-status="signupIdInputStatus"
              :feedback="signupIdFeedback"
            >
              <n-input
                v-model:value="signupId"
                type="text"
                placeholder="请输入账号"
                :loading="signupIdInputLoading"
                @blur="verifyAccount"
              />
            </n-form-item-row>
            <n-form-item-row
              label="邮箱"
              :validation-status="signupEmailInputStatus"
              :feedback="signupEmailFeedback"
            >
              <n-input
                v-model:value="signupEmail"
                type="text"
                :loading="signupEmailInputLoading"
                placeholder="请输入邮箱"
                @blur="verifyEmail"
              />
            </n-form-item-row>
            <n-form-item-row
              label=""
              :validation-status="signupVerificationCodeInputStatus"
              :feedback="signupVerificationCodeFeedback"
            >
              <n-input-group>
                <n-input
                  :style="{ width: '50%' }"
                  type="text"
                  v-model:value="signupVerificationCode"
                  placeholder="请输入验证码"
                />
                <n-button
                  type="primary"
                  ghost
                  @click="getVerificationCode"
                  :disabled="sms.disabled"
                >
                  <div v-if="!sms.disabled">获取验证码</div>
                  <div v-if="sms.disabled">{{ sms.count }} 秒后重新发送</div>
                </n-button>
              </n-input-group>
            </n-form-item-row>
            <n-form-item-row
              label="密码"
              :validation-status="signupPassword1InputStatus"
              :feedback="signupPassword1Feedback"
            >
              <n-input
                type="password"
                show-password-on="mousedown"
                placeholder="请输入密码"
                v-model:value="signupPassword1"
                @blur="verifyPassword1"
              />
            </n-form-item-row>
            <n-form-item-row
              label="重复密码"
              :validation-status="signupPassword2InputStatus"
              :feedback="signupPassword2Feedback"
            >
              <n-input
                type="password"
                show-password-on="mousedown"
                placeholder="请重复输入密码"
                v-model:value="signupPassword2"
                @blur="verifyPassword2"
              />
            </n-form-item-row>
          </n-form>
          <n-button
            type="primary"
            :disabled="signupButton"
            @click="signup"
            block
            secondary
            strong
          >
            注册
          </n-button>
        </n-tab-pane>
      </n-tabs>
    </n-modal>
    <!-- end -->

    <!-- 忘记密码弹窗 -->
    <n-modal
      v-model:show="showResetPasswordModal"
      preset="card"
      :style="ResetPasswordBodyStyle"
      size="huge"
      :bordered="false"
    >
      <n-tabs
        class="card-tabs"
        default-value="reset"
        size="large"
        animated
        pane-wrapper-style="margin: 0 -4px"
        pane-style="padding-left: 4px; padding-right: 4px; box-sizing: border-box;"
      >
        <n-tab-pane name="reset" tab="忘记密码">
          <n-form>
            <n-form-item-row
              label="邮箱"
              :validation-status="resetEmailInputStatus"
              :feedback="resetEmailFeedback"
            >
              <n-input
                v-model:value="resetEmail"
                type="text"
                :loading="resetEmailInputLoading"
                placeholder="请输入邮箱"
                @blur="resetVerifyEmail"
              />
            </n-form-item-row>
            <n-form-item-row
              label=""
              :validation-status="resetVerificationCodeInputStatus"
              :feedback="resetVerificationCodeFeedback"
            >
              <n-input-group>
                <n-input
                  :style="{ width: '50%' }"
                  type="text"
                  v-model:value="resetVerificationCode"
                  placeholder="请输入验证码"
                />
                <n-button
                  type="primary"
                  ghost
                  @click="resetGetVerificationCode"
                  :disabled="sms.disabled"
                >
                  <div v-if="!sms.disabled">获取验证码</div>
                  <div v-if="sms.disabled">{{ sms.count }} 秒后重新发送</div>
                </n-button>
              </n-input-group>
            </n-form-item-row>
            <n-form-item-row
              label="新密码"
              :validation-status="resetPasswordInputStatus"
              :feedback="resetPasswordFeedback"
            >
              <n-input
                placeholder="请输入新密码"
                type="text"
                v-model:value="newPassword"
                @blur="verifyPassword"
              />
            </n-form-item-row>
          </n-form>
          <n-button
            type="primary"
            :disabled="resetButton"
            @click="reset"
            block
            secondary
            strong
          >
            重置密码
          </n-button>
        </n-tab-pane>
      </n-tabs>
    </n-modal>
    <!-- end -->

    <div class="header">
      <n-grid cols="12">
        <n-gi span="2">
          <div class="logo">
            <router-link to="/">
              <n-gradient-text
                :gradient="{
                  from: '#ffce45',
                  to: '#ffffff',
                }"
                size="2rem"
              >
                IKAN
              </n-gradient-text>
            </router-link>
          </div>
        </n-gi>
        <n-gi span="6">
          <div class="menu" v-if="!showMore">
            <ul>
              <li>
                <router-link
                  :class="{ active: title === '首页' ? true : false }"
                  to="/"
                  >首页</router-link
                >
              </li>
              <li>
                <router-link
                  :class="{ active: title === '电影' ? true : false }"
                  :to="{
                    name: 'film',
                    params: { pTypeIndex: '1', title: '电影' },
                  }"
                  >电影</router-link
                >
              </li>
              <li>
                <router-link
                  :class="{ active: title === '动漫' ? true : false }"
                  :to="{
                    name: 'anime',
                    params: { pTypeIndex: 4, title: '动漫' },
                  }"
                  >动漫</router-link
                >
              </li>
              <li>
                <router-link
                  :class="{ active: title === '综艺' ? true : false }"
                  :to="{
                    name: 'variety',
                    params: { pTypeIndex: 3, title: '综艺' },
                  }"
                  >综艺</router-link
                >
              </li>
              <li>
                <router-link
                  :class="{ active: title === '电视剧' ? true : false }"
                  :to="{
                    name: 'teleplay',
                    params: { pTypeIndex: 2, title: '电视剧' },
                  }"
                  >电视剧</router-link
                >
              </li>
              <li>
                <router-link
                  :class="{ active: title === '更新表' ? true : false }"
                  :to="{ name: 'Schedule' }"
                  >更新表</router-link
                >
              </li>
              <li>
                <router-link
                  :class="{ active: title === '分类' ? true : false }"
                  :to="{ name: 'Classification' }"
                  >分类</router-link
                >
              </li>
            </ul>
          </div>
        </n-gi>
        <n-gi span="4">
          <div class="left">
            <div class="leftSon">
              <n-grid x-gap="15" :cols="4">
                <n-gi>
                  <img
                    src="../assets/img/header/search.png"
                    @click="searchActive = !searchActive"
                    style="width: 25px; height: 25px"
                  />
                </n-gi>
                <n-gi v-if="!showMore">
                  <!-- <img
                    src="https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg"
                    style="width: 25px; height: 25px; border-radius: 100%"
                  /> -->
                  <n-avatar
                    v-if="!isLogin"
                    round
                    size="small"
                    @click="showLoginModal = !showLoginModal"
                  >
                    登录
                  </n-avatar>
                  <n-dropdown
                    v-if="isLogin"
                    :options="userOptions"
                    @select="userMoreItemClick"
                  >
                    <n-avatar
                      v-if="isLogin"
                      round
                      size="small"
                      src="https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg"
                    />
                  </n-dropdown>
                </n-gi>
                <n-gi v-if="showMore">
                  <n-dropdown
                    :options="options"
                    size="medium"
                    trigger="click"
                    @select="showMoreItemClick"
                  >
                    <img
                      src="../assets/img/header/list.png"
                      style="width: 25px; height: 25px"
                    />
                  </n-dropdown>
                </n-gi>
                <n-gi>
                  <img
                    src="../assets/img/header/lishi.png"
                    style="width: 25px; height: 25px"
                    @click="historyActive = !historyActive"
                  />
                </n-gi>
                <n-gi>
                  <n-popconfirm
                    @positive-click="handlePositiveClick"
                    positive-text="确定"
                    negative-text="取消"
                  >
                    <template #trigger>
                      <img
                        src="../assets/img/header/anzuo.png"
                        style="width: 25px; height: 25px"
                      />
                    </template>
                    将下载app
                  </n-popconfirm>
                </n-gi>
              </n-grid>
            </div>
          </div>
        </n-gi>
      </n-grid>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  toRef,
  defineProps,
  getCurrentInstance,
  reactive,
  onMounted,
  h,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useMessage, NIcon } from "naive-ui";
import {
  PersonCircleOutline as UserIcon,
  Pencil as EditIcon,
  LogOutOutline as LogoutIcon,
} from "@vicons/ionicons5";
const router = useRouter();
const route = useRoute();
const store = useStore();

//消息提示
const message = useMessage();

const currentInstance = getCurrentInstance();
const { $axios } = currentInstance.appContext.config.globalProperties;

const props = defineProps(["title"]);

const showMore = ref(window.innerWidth < 1200);

const drawerWidth = ref("400px");

window.addEventListener("resize", () => {
  showMore.value = window.innerWidth < 1310;
  if (window.innerWidth < 500) {
    drawerWidth.value = "80vw";
  } else {
    drawerWidth.value = "400px";
  }
});

let userData = ref({});
let isLogin = ref(false);
onMounted(() => {
  //从缓存获取用户数据,判断登录状态是否过期
  userData.value = JSON.parse(localStorage.getItem("userData") || "{}");
  let currentTime = new Date().getTime(); //毫秒
  if (
    Object.keys(userData.value).length > 0 &&
    currentTime < userData.value.expired
  ) {
    isLogin.value = true;
    //将登录状态存进store
    store.commit("changeLoginState", isLogin.value);
    options.value[options.value.length - 1] = {
      label: "我的",
      key: "我的",
      children: [
        {
          label: "退出登录",
          key: "退出登录",
        },
      ],
    };
    //重新设置过期时间
    setTimeout(() => {
      $axios
        .get("/token/getUserByToken", {
          params: {
            token: userData.value.token,
          },
        })
        .then((res) => {
          let data = res.data.data;
          let days = 7;
          const user = ref({
            token: userData.value.token,
            user: data,
            expired: new Date().getTime() + days * 24 * 60 * 60 * 1000,
          });
          localStorage.setItem("userData", JSON.stringify(user.value));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 0);
  } else {
    userData.value = {};
    isLogin.value = false;
  }
});

//登录后的用户操作
const renderIcon = (icon) => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon),
    });
  };
};
let userOptions = ref([
  // {
  //   label: "用户资料",
  //   key: "用户资料",
  //   icon: renderIcon(UserIcon),
  // },
  // {
  //   label: "编辑用户资料",
  //   key: "编辑用户资料",
  //   icon: renderIcon(EditIcon),
  // },
  {
    label: "退出登录",
    key: "退出登录",
    icon: renderIcon(LogoutIcon),
  },
]);

function userMoreItemClick(key) {
  switch (key) {
    case "退出登录":
      logout();
      break;

    default:
      break;
  }
}

async function logout() {
  await $axios
    .get("/login/logout", {
      params: {
        token: userData.value.token,
      },
    })
    .then((res) => {
      let data = res.data.data;
      console.log(data);
      if (data.code === 200) {
        localStorage.removeItem("userData");
        isLogin.value = false;
        message.success(data.msg);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        message.error(data.msg);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

//登录注册弹窗
let showLoginModal = ref(false);
let tabsValue = ref("signin");
let showLoginModalKey = ref(0);
let signupButton = ref(false);
let loginBodyStyle = ref({
  width: "600px",
});
let id = ref("");
let password = ref("");
let loginIdStatus = ref("");
let loginPasswordStatus = ref("");
let loginInputStatus = ref(true);
//登录
async function login() {
  if (id.value.length <= 0) {
    loginIdStatus.value = "error";
    loginInputStatus.value = false;
  }
  if (password.value.length <= 0) {
    loginPasswordStatus.value = "error";
    loginInputStatus.value = false;
  }
  if (!loginInputStatus.value) {
    message.error("不能为空！");
    return;
  }
  await $axios
    .post("/login/login", {
      id: id.value,
      password: password.value,
    })
    .then((res) => {
      console.log(res);
      const data = res.data.data;
      if (data.code != 200) {
        message.error(data.msg);
        if (data.msg === "用户不存在") {
          loginIdStatus.value = "error";
          loginPasswordStatus.value = "";
        } else {
          loginIdStatus.value = "";
          loginPasswordStatus.value = "error";
        }
        return;
      }
      message.success(data.msg);
      //将用户信息和token存入缓存,并设置过期时间以毫秒为单位
      let days = 7;
      const userData = ref({
        token: data.token,
        user: data.user,
        expired: new Date().getTime() + days * 24 * 60 * 60 * 1000,
      });
      localStorage.setItem("userData", JSON.stringify(userData.value));

      showLoginModal.value = false;
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
    .catch((err) => {
      console.log(err);
    });
}
//注册
let signupId = ref("");
let signupIdInputStatus = ref("");
let signupIdFeedback = ref("");
let signupEmail = ref("");
let signupEmailInputStatus = ref("");
let signupEmailFeedback = ref("");
let signupVerificationCode = ref("");
let signupVerificationCodeInputStatus = ref("");
let signupVerificationCodeFeedback = ref("");
let signupPassword1 = ref("");
let signupPassword1InputStatus = ref("");
let signupPassword1Feedback = ref("");
let signupPassword2 = ref("");
let signupPassword2InputStatus = ref("");
let signupPassword2Feedback = ref("");
let signupIdInputLoading = ref(undefined);
let signupEmailInputLoading = ref(undefined);

//验证账号
function verifyAccount() {
  const reg = /^[\w-]{6,20}$/;
  if (!reg.test(signupId.value)) {
    signupIdInputStatus.value = "error";
    signupIdFeedback.value = "6到20位（字母，数字，下划线，减号）";
    return;
  }
  //查询数据库是否存在该用户名
  signupIdInputLoading.value = true;
  $axios
    .get("/user/existId/" + signupId.value, {})
    .then((res) => {
      signupIdInputLoading.value = undefined;
      let data = res.data.data;
      if (data === 0) {
        signupIdInputStatus.value = "error";
        signupIdFeedback.value = "该账号已经存在";
        return;
      } else {
        signupIdInputStatus.value = "";
        signupIdFeedback.value = "";
      }
    })
    .catch((err) => {
      signupIdInputLoading.value = undefined;
      console.log(err);
    });
}

//验证邮箱
function verifyEmail() {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!reg.test(signupEmail.value)) {
    signupEmailInputStatus.value = "error";
    signupEmailFeedback.value = "请输入正确的邮箱";
    return;
  }
  //查询数据库中是否存在该邮箱
  signupEmailInputLoading.value = true;
  $axios
    .get("/user/existEmail/" + signupEmail.value, {})
    .then((res) => {
      signupEmailInputLoading.value = undefined;
      let data = res.data.data;
      if (data === 1) {
        signupEmailInputStatus.value = "error";
        signupEmailFeedback.value = "该邮箱已经存在";
        return;
      } else {
        signupEmailInputStatus.value = "";
        signupEmailFeedback.value = "";
      }
    })
    .catch((err) => {
      signupEmailInputLoading.value = undefined;
      console.log(err);
    });
}

//获取验证码

// 验证码计时器
const sms = reactive({
  disabled: false,
  total: 60,
  count: 0,
});
// 计时器处理器
const timerHandler = () => {
  sms.count = sms.total;
  sms.disabled = true;

  let timer = setInterval(() => {
    if (sms.count > 1 && sms.count <= sms.total) {
      sms.count--;
    } else {
      sms.disabled = false;
      clearInterval(timer);
    }
  }, 1000);
};
//注册验证码获取
function getVerificationCode() {
  if (
    signupEmail.value.length <= 0 ||
    signupEmailInputStatus.value === "error"
  ) {
    signupEmailInputStatus.value = "error";
    signupEmailFeedback.value = "请输入正确的邮箱";
    return;
  }
  timerHandler();

  //获取验证码
  $axios
    .post("/code/getCode", {
      email: signupEmail.value,
    })
    .then((res) => {
      let data = res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

//验证密码和重复密码
function verifyPassword1() {
  const reg = /^\S{6,}$/;
  if (!reg.test(signupPassword1.value)) {
    signupPassword1InputStatus.value = "error";
    signupPassword1Feedback.value = "密码至少6位";
  } else {
    signupPassword1InputStatus.value = "";
    signupPassword1Feedback.value = "";
  }
}
function verifyPassword2() {
  const reg = /^\S{6,}$/;
  if (!reg.test(signupPassword2.value)) {
    signupPassword2InputStatus.value = "error";
    signupPassword2Feedback.value = "密码至少6位";
    return;
  }
  if (signupPassword1.value != signupPassword2.value) {
    signupPassword2InputStatus.value = "error";
    signupPassword2Feedback.value = "密码不相同";
  } else {
    signupPassword2InputStatus.value = "";
    signupPassword2Feedback.value = "";
  }
}

//注册
async function signup() {
  if (
    !signupIdInputStatus.value.length <= 0 ||
    !signupId.value.length > 0 ||
    !signupEmailInputStatus.value.length <= 0 ||
    !signupEmail.value.length > 0 ||
    !signupPassword1InputStatus.value.length <= 0 ||
    !signupPassword1.value.length > 0 ||
    !signupPassword2InputStatus.value.length <= 0 ||
    !signupPassword2.value.length > 0
  ) {
    return;
  }
  signupButton.value = true;
  //验证验证码是否正确
  await $axios
    .post("/code/verifyCode", {
      email: signupEmail.value,
      code: signupVerificationCode.value,
    })
    .then((res) => {
      let data = res.data.data;
      if (data === 1) {
        signupButton.value = false;
        signupVerificationCodeInputStatus.value = "";
        signupVerificationCodeFeedback.value = "";
        if (register() === 0) {
          return;
        }
        //注册成功后跳转登录
        tabsValue.value = "signin";
        showLoginModalKey.value = showLoginModalKey.value + 1;
      } else {
        signupButton.value = false;
        signupVerificationCodeInputStatus.value = "error";
        signupVerificationCodeFeedback.value = "验证码不正确";
      }
    })
    .catch((err) => {
      signupButton.value = false;
      console.log(err);
    });
}

//注册方法
async function register() {
  await $axios
    .post("/user/register", {
      userId: signupId.value,
      email: signupEmail.value,
      password: signupPassword1.value,
    })
    .then((res) => {
      let data = res.data.data;
      if (data === 1) {
        message.success("注册成功");
        return 1;
      } else {
        message.error("注册失败");
        return 0;
      }
    })
    .catch((err) => {
      message.error("注册失败");
      console.log(err);
      return 0;
    });
}
//end

//忘记密码
let showResetPasswordModal = ref(false);
let ResetPasswordBodyStyle = ref({
  width: "600px",
});
let resetButton = ref(false);
let resetEmailInputStatus = ref("");
let resetEmailFeedback = ref("");
let resetEmail = ref("");
let resetEmailInputLoading = ref(undefined);
let resetVerificationCode = ref("");
let resetVerificationCodeInputStatus = ref("");
let resetVerificationCodeFeedback = ref("");
let newPassword = ref("");
let resetPasswordFeedback = ref("");
let resetPasswordInputStatus = ref("");

//验证邮箱是否合法且数据库中存在该邮箱
function resetVerifyEmail() {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!reg.test(resetEmail.value)) {
    resetEmailInputStatus.value = "error";
    resetEmailFeedback.value = "请输入正确的邮箱";
    return;
  }
  //查询数据库中是否存在该邮箱
  resetEmailInputLoading.value = true;
  $axios
    .get("/user/existEmail/" + resetEmail.value, {})
    .then((res) => {
      resetEmailInputLoading.value = undefined;
      let data = res.data.data;
      if (data === 1) {
        resetEmailInputStatus.value = "";
        resetEmailFeedback.value = "";
        return;
      } else {
        resetEmailInputStatus.value = "error";
        resetEmailFeedback.value = "该邮箱没有绑定账户";
      }
    })
    .catch((err) => {
      resetEmailInputLoading.value = undefined;
      console.log(err);
    });
}

//获取验证码
function resetGetVerificationCode() {
  if (resetEmailInputStatus.value.length > 0 || resetEmail.value.length <= 0) {
    resetEmailInputStatus.value = "error";
    return;
  }
  timerHandler();

  //获取验证码
  $axios
    .post("/code/getCode", {
      email: resetEmail.value,
    })
    .then((res) => {
      let data = res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

//验证密码正确性
function verifyPassword() {
  const reg = /^\S{6,}$/;
  if (!reg.test(newPassword.value)) {
    resetPasswordInputStatus.value = "error";
    resetPasswordFeedback.value = "密码至少6位";
  } else {
    resetPasswordInputStatus.value = "";
    resetPasswordFeedback.value = "";
  }
}

//重置密码
async function reset() {
  if (
    resetEmailInputStatus.value.length > 0 ||
    resetEmail.value.length <= 0 ||
    resetPasswordInputStatus.value.length > 0 ||
    newPassword.value.length <= 0
  ) {
    return;
  }
  resetButton.value = true;
  await $axios
    .post("/code/verifyCode", {
      email: resetEmail.value,
      code: resetVerificationCode.value,
    })
    .then((res) => {
      let data = res.data.data;
      if (data === 1) {
        resetVerificationCodeInputStatus.value = "";
        resetVerificationCodeFeedback.value = "";
        //重置密码
        $axios
          .post("/user/retrieve", {
            id: resetEmail.value,
            password: newPassword.value,
          })
          .then((res) => {
            let data = res.data.data;
            if (data === 1) {
              message.success("重置密码成功");
              showResetPasswordModal.value = false;
              showLoginModal.value = true;
            } else {
              message.error("重置失败");
            }
            resetButton.value = false;
          })
          .catch((err) => {
            message.error("重置失败");
            resetButton.value = false;
            console.log(err);
          });
      } else {
        resetVerificationCodeInputStatus.value = "error";
        resetVerificationCodeFeedback.value = "验证码不正确";
        resetButton.value = false;
      }
    })
    .catch((err) => {
      resetButton.value = false;
      console.log(err);
    });
}
//end

//下面是搜索弹框需要的值
let searchActive = ref(false);
let SearchHistoryList = ref([]);
//下面是搜索弹框的方法
function maskClick() {
  searchActive.value = !searchActive.value;
}

function afterEnter() {
  SearchHistoryList.value = JSON.parse(localStorage.getItem("SearchHistory"))
    ? JSON.parse(localStorage.getItem("SearchHistory"))
    : [];
}
// end

//下面是观看历史弹框
let historyActive = ref(false);
let historyVodData = ref([]);

function historyMaskClick() {
  historyActive.value = !historyActive.value;
}
function historyAfterEnter() {
  //获取本地内存中的历史记录
  historyVodData.value = JSON.parse(localStorage.getItem("vodHistory"))
    ? JSON.parse(localStorage.getItem("vodHistory"))
    : [];
}
function removeHistory() {
  localStorage.removeItem("vodHistory");
  historyVodData.value = [];
}
function historyImgClick(vodId, anthology) {
  const href = router.resolve({
    //使用resolve
    name: "Player", //这里是跳转页面的name
    params: { vodId, anthology: anthology },
  });
  window.open(href.href, "_blank");
}
//end

const options = toRef([
  {
    label: "首页",
    key: "首页",
  },
  {
    label: "电影",
    key: "电影",
  },
  {
    label: "动漫",
    key: "动漫",
  },
  {
    label: "综艺",
    key: "综艺",
  },
  {
    label: "电视剧",
    key: "电视剧",
  },
  {
    label: "更新表",
    key: "更新表",
  },
  {
    label: "分类",
    key: "分类",
  },
  {
    label: "登录",
    key: "登录",
  },
]);

//更多选择事件
function showMoreItemClick(key) {
  switch (key) {
    case "首页":
      router.push({
        name: "Index",
      });
      break;
    case "电影":
      router.push({
        name: "film",
        params: { pTypeIndex: "1", title: "电影" },
      });
      break;
    case "动漫":
      router.push({
        name: "anime",
        params: { pTypeIndex: 4, title: "动漫" },
      });
      break;
    case "综艺":
      router.push({
        name: "variety",
        params: { pTypeIndex: 3, title: "综艺" },
      });
      break;
    case "电视剧":
      router.push({
        name: "teleplay",
        params: { pTypeIndex: 2, title: "电视剧" },
      });
      break;
    case "更新表":
      router.push({
        name: "Schedule",
      });
      break;
    case "分类":
      router.push({ name: "Classification" });
      break;
    case "登录":
      showLoginModal.value = true;
      break;
    case "退出登录":
      logout();
      break;
    default:
      break;
  }
}

//app下载确定事件
async function handlePositiveClick() {
  await $axios
    .get("/version/version", {})
    .then((res) => {
      let data = res.data.data;
      let url = data.downloadUrl;
      window.location.href = url;
    })
    .catch((err) => {
      console.log(err);
    });
}

//搜索提示
let searchItems = ref([]);
function handleInputChange() {
  getSearchItem();
}
function searchItemClick(key) {
  searchText.value = key;
  searchItems.value = [];
  searchClick();
}
//end
let searchText = ref("");
function searchClick() {
  let SearchHistoryItem = searchText.value;
  //读取缓存中原有的搜索历史记录
  SearchHistoryList.value = JSON.parse(localStorage.getItem("SearchHistory"))
    ? JSON.parse(localStorage.getItem("SearchHistory"))
    : [];
  for (let i in SearchHistoryList.value) {
    //循环遍历
    if (SearchHistoryList.value[i] == SearchHistoryItem) {
      //如果缓存数组中有搜索关键词
      SearchHistoryList.value.splice(i, 1); //删除数组中的该关键词
    }
  }
  SearchHistoryList.value.unshift(SearchHistoryItem); //将搜索关键词添加到数组开头
  SearchHistoryList.value.splice(10); //只保留10个
  //将搜索数据存入缓存中
  localStorage.setItem(
    "SearchHistory",
    JSON.stringify(SearchHistoryList.value)
  );
  //跳转到搜索详情页面并把搜索内容传递过去
  // searchText.value = "";
  const href = router.resolve({
    //使用resolve
    name: "SearchDetail", //这里是跳转页面的name
    params: { searchText: SearchHistoryItem },
  });
  window.open(href.href, "_blank");
}

function clickSearchHistoryItem(item) {
  searchText.value = item;
  searchClick();
}

function deleteSearchHistory() {
  localStorage.removeItem("SearchHistory");
  SearchHistoryList.value = JSON.parse(localStorage.getItem("SearchHistory"))
    ? JSON.parse(localStorage.getItem("SearchHistory"))
    : [];
}

//==============================================
async function getSearchItem() {
  await $axios
    .get("/search/matchingItems", {
      params: {
        searchText: searchText.value,
      },
    })
    .then((res) => {
      let data = res.data.data;
      if (data) {
        searchItems.value = data;
      } else {
        searchItems.value = [];
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
</script>

<style scoped>
input,
button {
  border: none;
  outline: none;
}
.container {
  position: sticky;
  top: 0px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 1px 0 #ffffff;
  -webkit-box-shadow: 0px 0px 10px 1px #f2eaea;
}
.header {
  display: flex;
  flex-direction: row;
  height: 65px;
  width: 100%;
  background: #080808;
}

.logo {
  height: 100%;
  text-align: center;
}
.menu {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: left;
}
.menu li {
  float: left;
  width: 90px;
  text-align: center;
  align-self: center;
}
.menu li a {
  font-family: "微软雅黑";
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}
.menu li a:hover {
  color: #eb315a;
}
.menu li .active {
  color: #eb315a;
}
.left {
  height: 100%;
  display: flex;
  justify-content: right;
  margin-right: 15px;
}
.leftSon {
  align-self: center;
}
.drawerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.searchInput {
  display: flex;
  align-items: center;
}
.searchInput input {
  background-color: #111319;
  border: 1px solid #141b29;
  box-shadow: 0 0 10px #141b29;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  color: #ffffff;
}
.searchInput input:hover {
  border: 1px solid #ff0000;
}
.searchInput button {
  background-color: red;
  width: 50px;
  height: 51px;
  border-radius: 10px;
  margin-left: -50px;
}
.searchInput img {
  width: 40px;
  height: 40px;
}
.searchHistory {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.searchHistory-top {
  display: flex;
  align-items: center;
  width: 100%;
}
.searchHistory-top-left {
  width: 50%;
  text-align: left;
  font-size: 2vh;
  color: #ffffff;
}
.searchHistory-top-right {
  width: 50%;
  font-size: 2vh;
  text-align: right;
}
.searchHistory-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.searchHistoryItem {
  height: auto;
  margin: 10px;
  border-radius: 5px;
  background: rgba(
    255,
    255,
    255,
    0
  ); /* 使用rgba表示透明的白色背景（透明度为0.5） */
  padding: 0px 20px 0px 20px; /* 添加内边距 */
  border: 1px solid white;
  text-align: center;
}
.searchHistoryItem p {
  font-size: 1.8vh;
  color: #ffffff;
}

.clearHistory {
  width: 100%;
  height: auto;
}
.clearHistory button {
  width: 100%;
  height: 40px;
  border: none;
  background-color: red;
  color: #ffffff;
  border-radius: 10px;
}
.clearHistory button:hover {
  background-color: #7a0d0d;
  color: #969191;
}
.historyContent {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.historyItem {
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.imgBox {
  width: 110px;
  height: 15vh;
}
.imgBox img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
}
.historyItemIntro {
  width: 230px;
  height: 15vh;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  margin-left: 5px;
}

@media screen and (max-width: 450px) {
  .header {
    height: 50px;
    width: 100%;
  }
  .imgBox {
    width: 90px;
  }
  .historyItemIntro {
    width: 210px;
  }
}
</style>