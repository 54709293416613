<template>
  <div class="container">
    <Header title="首页"></Header>
    <!-- 固定钉——联系我 -->
    <div class="contactInformation">
      <n-affix :trigger-top="100" position="fixed">
        <n-tag round :bordered="false">
          <n-tooltip placement="bottom" trigger="click">
            <template #trigger>
              <img src="../../assets/img/index/customer_service.png" />
            </template>
            <!-- <span> QQ:445960228<br />邮箱:445960228@qq.com </span> -->
          </n-tooltip>
        </n-tag>
      </n-affix>
    </div>
    <!-- end -->

    <!-- 模态框 -->
    <n-modal :show="modalShow">
      <n-card
        style="width: 45vh"
        title="域名防失效通知"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
      >
        <p>
          请务必记住我们的域名发布地址：https://gitee.com/li-jiazhen0623/ikanUrl
        </p>
        <p>也可点击右上角下载我们的app直接跳转网页</p>
        <template #footer>
          <div class="modalButton">
            <button @click="modalShow = !modalShow">知道了</button>
          </div>
        </template>
      </n-card>
    </n-modal>
    <!-- end -->

    <div>
      <!-- 轮播图 -->
      <n-carousel
        effect="card"
        autoplay
        prev-slide-style="transform: translateX(-150%) translateZ(-800px);"
        next-slide-style="transform: translateX(50%) translateZ(-800px);"
        class="carousel"
        :show-dots="false"
      >
        <n-carousel-item :style="{ width: '60%' }">
          <img
            class="carousel-img"
            src="https://naive-ui.oss-cn-beijing.aliyuncs.com/carousel-img/carousel1.jpeg"
          />
        </n-carousel-item>
        <n-carousel-item :style="{ width: '60%' }">
          <img
            class="carousel-img"
            src="https://naive-ui.oss-cn-beijing.aliyuncs.com/carousel-img/carousel2.jpeg"
          />
        </n-carousel-item>
        <n-carousel-item :style="{ width: '60%' }">
          <img
            class="carousel-img"
            src="https://naive-ui.oss-cn-beijing.aliyuncs.com/carousel-img/carousel3.jpeg"
          />
        </n-carousel-item>
        <n-carousel-item :style="{ width: '60%' }">
          <img
            class="carousel-img"
            src="https://naive-ui.oss-cn-beijing.aliyuncs.com/carousel-img/carousel4.jpeg"
          />
        </n-carousel-item>
      </n-carousel>
      <!-- end -->
    </div>

    <div class="content">
      <!-- 热门动漫列表 -->
      <hot-vod-list
        title="热门国漫"
        typeName="动漫"
        area="中国"
        other="最热"
      ></hot-vod-list>
      <!-- end -->
      <!-- 热门番剧列表 -->
      <hot-vod-list
        title="热门番剧"
        typeName="动漫"
        area="日本"
        other="最热"
      ></hot-vod-list>
      <!-- end -->
      <!-- 热门电影 -->
      <hot-vod-list
        title="热门电影"
        typeName="电影"
        other="最热"
      ></hot-vod-list>
      <!-- end -->
      <!-- 热门综艺 -->
      <hot-vod-list
        title="热门综艺"
        typeName="综艺"
        area="中国"
        other="最热"
      ></hot-vod-list>
      <!-- end -->
      <!-- 热门电视剧 -->
      <hot-vod-list
        title="热门电视剧"
        typeName="电视剧"
        area="中国"
        other="最热"
      ></hot-vod-list>
      <!-- end -->
    </div>
    <Footer />
    <!-- 返回顶部 -->
    <n-back-top :right="40" />
  </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import HotVodList from "@/components/HotVodList.vue";
import Footer from "@/components/Footer.vue";
import { ref, onMounted } from "vue";
import { useNotification } from "naive-ui";

let modalShow = ref(false);

const notification = useNotification();

onMounted(() => {
  inform();
});

function inform() {
  notification.create({
    title: "域名防失效通知",
    content: `请务必记住我们的域名发布地址:\nhttps://gitee.com/li-jiazhen0623/ikanUrl\n\n也可点击右上角下载我们的app直接跳转网页`,
    duration: 15000,
  });
}
</script>
<style scoped>
.carousel {
  margin-top: 10px;
  height: 350px;
}

.contactInformation {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

.contactInformation img {
  height: 25px;
  width: 25px;
}

@media screen and (max-width: 450px) {
  .carousel {
    height: 200px;
  }
}
.carousel-img {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content {
  width: 80%;
  margin: 0 10% 0 10%;
}

.videoList {
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.title {
  display: flex;
  flex-direction: row;
  height: auto;
  color: #faf8f8;
}
.title-left {
  width: 50%;
  text-align: left;
  font-size: 2vw;
  font-weight: bold;
}
.title-right {
  width: 50%;
  text-align: right;
  align-self: center;
}
.vodImgBox {
  height: 80%;
  width: 100%;
}
.vodImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.vodName {
  width: 100%;
  display: block;
  overflow: hidden;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.modalButton {
  width: 100%;
}
.modalButton button {
  border: none;
  background-color: red;
  color: #ffffff;
  border-radius: 5px;
  width: 100%;
  font-size: 2.5vh;
  padding: 5px;
}
</style>