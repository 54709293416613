import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import naive from "naive-ui";
import axios from "axios";

const app = createApp(App);

axios.defaults.baseURL = "/api"; //http://139.196.27.155:8081
app.config.globalProperties.$axios = axios;

app.use(store).use(router).use(naive).mount("#app");
