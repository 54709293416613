<template>
  <div class="container">
    <Header title="搜索详情"></Header>
    <div class="content">
      <div class="searchBox">
        <div class="searchInput">
          <n-dropdown
            class="dropdown"
            :show="true"
            :options="searchItems"
            label-field="vodName"
            key-field="vodName"
            @select="searchItemClick"
            style="width: 300px;"
          >
            <div class="searchInput-1">
              <input
                type="text"
                @input="handleInputChange"
                v-model="searchText"
              />
              <img src="@/assets/img/searchDetail/search.png" @click="search" />
            </div>
          </n-dropdown>
        </div>
        <div class="vodTypes">
          <n-tabs
            class="vodType"
            @update:value="otherTypeUpdate"
            type="bar"
            size="medium"
          >
            <n-tab name="全部"> </n-tab>
            <n-tab name="电影"> </n-tab>
            <n-tab name="动漫"> </n-tab>
            <n-tab name="电视剧"> </n-tab>
            <n-tab name="综艺"> </n-tab>
          </n-tabs>
        </div>
      </div>
      <div class="vodListBox">
        <div class="vodList">
          <div class="vodItem" v-for="(item, index) in vodList" :key="index">
            <div class="vodImgBox">
              <img class="vodImg" :src="item.vod_pic" />
            </div>
            <div class="intro">
              <div class="vodIntro">
                <p style="font-weight: bold; color: #ffffff">
                  {{ item.vod_name }}
                </p>
              </div>
              <div class="vodIntro">
                <p>
                  {{ item.vod_year }} {{ item.vod_area }} {{ item.vod_class }}
                </p>
              </div>
              <div class="vodIntro">
                <p style="font-weight: bold; color: #ffffff">导演：</p>
                <n-ellipsis :line-clamp="2">
                  {{ item.vod_director ? item.vod_director : "暂无" }}
                </n-ellipsis>
              </div>
              <div class="vodIntro" style="height: 80px">
                <div>
                  <p style="font-weight: bold; color: #ffffff">演员：</p>
                </div>
                <n-ellipsis :line-clamp="2">
                  {{ item.vod_actor ? item.vod_actor : "暂无" }}
                </n-ellipsis>
              </div>
              <div class="vodIntro" style="height: 80px">
                <n-ellipsis :line-clamp="3">
                  {{ item.vod_blurb }}
                </n-ellipsis>
              </div>
              <div class="play">
                <button @click="play(item.vod_id)">
                  <img src="@/assets/img/searchDetail/play.png" />
                  <p>立即播放</p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <div
            v-if="vodList.length === 0"
            style="color: #ffffff; font-weight: bold"
          >
            没有更多了
          </div>
          <n-pagination
            v-if="vodList.length > 0"
            :page-slot="5"
            :page="page"
            :page-size="size"
            :page-count="totalPage"
            show-size-picker
            :page-sizes="pageSizes"
            @update:page="updatePage"
            @update:page-size="updatePageSize"
          />
        </div>

        <n-back-top :right="90" />
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { getCurrentInstance, defineProps, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

const props = defineProps(["searchText"]);
const router = useRouter();
const route = useRoute();

const currentInstance = getCurrentInstance();
const { $axios } = currentInstance.appContext.config.globalProperties;

let vodList = ref([]);
let searchText = ref(props.searchText);
let typeName = ref("");
let totalPage = ref(0);
let page = ref(1);
let size = ref(20);
const pageSizes = [
  {
    label: "10 每页",
    value: 10,
  },
  {
    label: "20 每页",
    value: 20,
  },
  {
    label: "30 每页",
    value: 30,
  },
  {
    label: "40 每页",
    value: 40,
  },
];
let searchItems = ref([]);

onMounted(() => {
  getSearchVod();
});

function search() {
  getSearchVod();
}
//搜索提示
function handleInputChange(event) {
  getSearchItem();
}

function searchItemClick(key) {
  searchText.value = key;
  searchItems.value = [];
  getSearchVod();
}
//end
function otherTypeUpdate(name) {
  switch (name) {
    case "全部":
      typeName.value = "";
      break;
    case "电影":
      typeName.value = "片";
      break;
    case "电视剧":
      typeName.value = "剧";
      break;
    case "纪录片":
      typeName.value = "纪录";
      break;
    default:
      typeName.value = name;
      break;
  }
  vodList.value = [];
  getSearchVod();
}

//分页方法
function updatePage(currentPage) {
  page.value = currentPage;
  getSearchVod();
  window.scrollTo({
    top: 0,
    behavior: "smooth", // 可选的平滑滚动效果
  });
}
function updatePageSize(currentPageSize) {
  size.value = currentPageSize;
  getSearchVod();
  window.scrollTo({
    top: 0,
    behavior: "smooth", // 可选的平滑滚动效果
  });
}
//end

//点击播放
function play(vodId) {
  router.push({ name: "Player", params: { vodId: vodId ,anthology:1} });
}
//============================================
async function getSearchVod() {
  await $axios
    .get("/search/searchVod", {
      params: {
        searchText: searchText.value,
        typeName: typeName.value,
        current: page.value,
        size: size.value,
      },
    })
    .then((res) => {
      let data = res.data.data;
      // console.log(res.data.data);
      totalPage.value = data.pages;
      vodList.value = data.records;
    })
    .catch((err) => {
      console.log(err);
    });
}

async function getSearchItem() {
  await $axios
    .get("/search/matchingItems", {
      params: {
        searchText: searchText.value,
      },
    })
    .then((res) => {
      let data = res.data.data;
      if (data) {
        searchItems.value = data;
      } else {
        searchItems.value = [];
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
</script>

<style scoped>
input {
  border: none;
  outline: none;
}
.container {
  background-image: url("@/assets/img/searchDetail/1.jpg");
  background-size: contain;
  background-position: center center;
}
.searchBox {
  width: 100%;
  height: 30vh;
  background-color: rgba(0, 0, 0, 0.9);
  position: relative;
}
.searchInput {
  position: absolute;
  top: 50%; /* 将子元素的顶部定位到父容器中央 */
  left: 50%; /* 将子元素的左侧定位到父容器中央 */
  transform: translate(-50%, -50%); /* 通过平移来水平和垂直居中 */
}
.searchInput-1 {
  display: flex;
  align-items: center;
  margin: 0 30% 0 30%;
}
.searchInput img {
  width: 45px;
  height: 45px;
}
.searchInput input {
  width: 100vw;
  border-bottom: 1px solid #3d3d46;
  background: rgba(255, 255, 255, 0);
  color: #ffffff;
  font-size: 2.3vh;
  text-align: center;
  font-weight: bold;
}
.vodTypes {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vodType >>> .n-tabs-tab {
  color: #ffffff !important;
  font-size: 1.8vh;
  font-weight: bold;
}
.vodType >>> .n-tabs-tab--active .n-tabs-tab__label {
  color: #ff3232;
  font-size: 2vh;
  font-weight: bold;
}
.vodType >>> .n-tabs-tab__label:hover {
  color: #ff3232 !important;
}
.vodType >>> .n-tabs-bar {
  background-color: #ff3232 !important;
}
.vodListBox {
  background-color: rgba(0, 0, 0, 0.9);
}
.vodList {
  margin: 0 10% 0 10%;
  /* border: 1px solid white; */
}
.vodItem {
  height: 270px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px;
}
.vodImgBox {
  height: 100%;
  width: 15%;
}
.vodImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.6s; /*设置动画执行的时间为0.6s */
  cursor: pointer;
}
.vodImg:hover {
  transform: scale(1.2); /*设置图片按照比例放大1.3倍 */
}
.intro {
  height: 100%;
  width: 85%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  margin-left: 10px;
}
.vodIntro {
  height: 25px;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #ababad;
  overflow: hidden;
}
.play button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  border: none;
  background-color: red;
  margin-top: 5px;
}
.play button:hover {
  background-color: rgb(163, 10, 10);
}
.play img {
  width: 25px;
  height: 25px;
}
.play p {
  font-size: 10px;
  color: #ffffff;
  font-weight: bold;
}
@media screen and (max-width: 480px) {
  .searchBox {
    height: 150px;
  }
  .searchInput-1 {
    margin: 0 10% 0 10%;
  }
  .searchInput img {
    width: 30px;
    height: 30px;
  }
  .vodItem {
    height: 190px;
  }
  .vodImgBox {
    width: 40%;
  }
  .intro {
    width: 60%;
  }
  .vodIntro {
    height: 30px;
    font-size: 15px;
    white-space: nowrap;
  }
}
.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 10px;
}
.pagination >>> .n-pagination {
  color: #ffffff;
}
.pagination >>> .n-pagination-item {
  background-color: #000000 !important;
  border: none !important;
  color: #ffffff !important;
}
.pagination >>> .n-pagination-item--active {
  background-color: #ff0000 !important;
}
.pagination >>> .n-base-selection-label {
  background-color: #000000 !important;
  color: #ffffff !important;
}
.pagination >>> .n-base-selection-input__content {
  color: #ffffff !important;
}
</style>