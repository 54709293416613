<template>
  <div class="container">
    <div class="content">
      <div style="display: flex; flex-direction: row; align-items: center">
        <p>友情连接：</p>
        <!-- <a href="https://evolution-host.com/">Evolution Host</a> -->
      </div>
      <div>
        <p>
          本站所有视频和图片均来自互联网收集而来，本网站只提供web页面服务，并不提供资源存储，也不参与录制、上传
          若本站收录的节目无意侵犯了贵司版权，请发邮件至
          （我们会在3个工作日内删除侵权内容，谢谢。）
        </p>
      </div>
    </div>
    <div class="copyright">
      <p>Copyright © 2023 www.ikan.wang All Right Reserved.</p>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
.container {
  width: 100%;
  height: auto;
  margin-top: auto;
  background-color: red;
  text-align: center;
}
.content p {
  color: #ffffff;
}
</style>