import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      currentVodUrl: "",
      loginState: false,
    };
  },
  getters: {
    currentVodUrlData: function (state) {
      return state.currentVodUrl;
    },
    loginStateData: function (state) {
      return state.loginState;
    },
  },
  mutations: {
    changeCurrentVodUrl(state, newUrl) {
      state.currentVodUrl = newUrl;
    },
    changeLoginState(state, newLoginState) {
      state.loginState = newLoginState;
    },
  },
  actions: {},
  modules: {},
});
