<template>
  <div class="container">
    <Header></Header>

    <div class="content">
      <div class="videoBox" ref="videoBox">
        <Video :url="url" :vodId="vodId" :episode="anthology"></Video>
      </div>
      <div class="introBox">
        <!-- 100vh 等于屏幕高度 -->
        <n-scrollbar style="max-height: 90vh">
          <Intro :vodId="vodId" :anthology="anthology"></Intro>
        </n-scrollbar>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import Intro from "@/components/Intro.vue";
import Video from "@/components/Video.vue";
import Footer from "@/components/Footer.vue";
import { useStore } from "vuex";
import { onMounted, ref, defineProps, watch, computed } from "vue";
import { useMessage } from "naive-ui";

const props = defineProps(["vodId", "anthology"]);
const store = useStore();

let vodId = ref("");
let anthology = ref(1);
let url = ref("");
onMounted(() => {
  vodId.value = props.vodId;
  anthology.value = props.anthology;
  //通知
  inform();
});

//监听store由intro组件存进去的视频地址
const getCurrentVodUrl = computed(() => {
  return store.getters["currentVodUrlData"];
});
watch(
  getCurrentVodUrl,
  (newVal) => {
    url.value = newVal;
    //因为在选集组件中把当前播放的视频和地址选的集坐标都存进了本地缓存里面，所以在本地存储中获取当前集
    let currentVodData = JSON.parse(
      localStorage.getItem(props.vodId + "_VodData")
    );
    if (currentVodData) {
      anthology.value = currentVodData.currentAnthologyListIndex + 1;
    }
  },
  { immediate: true, deep: true }
);

//通知
const message = useMessage();
function inform() {
  message.info(
    "晚间时段网络拥堵，加载可能比较慢，如遇卡顿或加载过久，刷新页面 或 更换网络。",
    {
      duration: 5000,
    }
  );
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.content {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.videoBox {
  width: 70%;
}
.introBox {
  width: 30%;
  margin: 0 10px 0 10px;
}
@media screen and (max-width: 1000px) {
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .videoBox {
    width: 100%;
  }
  .introBox {
    width: 100%;
    margin: 0 10px 0 10px;
  }
}
</style>