import { createRouter, createWebHistory } from "vue-router";
import Index from "@/views/Index/Index.vue";
import Player from "@/views/Player/Player.vue";
import Classification from "@/views/Classification/Classification.vue";
import SearchDetail from "@/views/SearchDetail/SearchDetail.vue";
import Schedule from "@/views/Schedule/Schedule.vue";
import VodDetail from "@/views/VodDetail/VodDetail.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    meta: { title: "首页-爱看视频" },
    component: Index,
  },
  {
    path: "/SearchDetail/:searchText",
    name: "SearchDetail",
    meta: { title: "搜索详情-爱看视频" },
    component: SearchDetail,
    props: true,
  },
  {
    path: "/Classification",
    name: "Classification",
    meta: { title: "分类-爱看视频" },
    component: Classification,
  },
  {
    path: "/film/:pTypeIndex/:title",
    name: "film",
    meta: { title: "电影-爱看视频" },
    component: Classification,
    props: true,
  },
  {
    path: "/anime/:pTypeIndex/:title",
    name: "anime",
    meta: { title: "动漫-爱看视频" },
    component: Classification,
    props: true,
  },
  {
    path: "/variety/:pTypeIndex/:title",
    name: "variety",
    meta: { title: "综艺-爱看视频" },
    component: Classification,
    props: true,
  },
  {
    path: "/teleplay/:pTypeIndex/:title",
    name: "teleplay",
    meta: { title: "电视剧-爱看视频" },
    component: Classification,
    props: true,
  },
  {
    path: "/more/:pTypeIndex/:area?/:other?",
    name: "more",
    meta: { title: "爱看视频" },
    component: Classification,
    props: true,
  },
  {
    path: "/Player/:vodId/:anthology",
    name: "Player",
    component: Player,
    props: true,
  },
  {
    path: "/Schedule",
    name: "Schedule",
    component: Schedule,
    props: true,
  },
  {
    path: "/VodDetail/:vodId",
    name: "VodDetail",
    component: VodDetail,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  }
  next(); //执行进入路由，如果不写就不会进入目标页
});

// 百度统计
// router.afterEach((to, from, next) => {
//   setTimeout(() => {
//     var _hmt = _hmt || [];
//     (function () {
//       //每次执行前，先移除上次插入的代码
//       document.getElementById("baidu_tj") &&
//         document.getElementById("baidu_tj").remove();
//       var hm = document.createElement("script");
//       hm.src = "https://hm.baidu.com/hm.js?d1213780784588d1bac17d7275c1bd95";
//       hm.id = "baidu_tj";
//       var s = document.getElementsByTagName("script")[0];
//       s.parentNode.insertBefore(hm, s);
//     })();
//   }, 0);
// });

export default router;
