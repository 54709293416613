<template>
  <div>
    <div class="video">
      <div class="dplayer" ref="dplayer"></div>

      <div class="controlStrip">
        <!-- 功能栏 -->
      </div>
    </div>
  </div>
</template>

<script setup>
import DPlayer from "dplayer";
import Hls from "hls.js";
import { onMounted, defineProps, ref, watchEffect } from "vue";
import { useStore } from "vuex";

const props = defineProps(["url", "vodId", "episode"]);

const store = useStore();

const dplayer = ref();
let dp;

let vodUrl = ref("");
let isLogin = ref(false);

let token = ref("");
let userId = ref("");

let danmuApiUrl = ref("/api/danmu/");

let seekTime = ref(0);
onMounted(() => {
  isLogin.value = store.state.loginState;
  //判断登录状态
  if (isLogin.value) {
    let userData = JSON.parse(localStorage.getItem("userData") || "{}");
    token.value = userData.token;
    userId.value = userData.user.userId;
  }

  //初始化播放时间
  seekTime.value = Number(
    localStorage.getItem(
      props.vodId + "_" + props.episode + "_hasBeenPlayedTime"
    ) || 0
  );

  setTimeout(() => {
    initPlayer();
  }, 500);
});

function initPlayer() {
  dp = new DPlayer({
    container: dplayer.value,
    autoplay: false,
    theme: "#0093ff",
    loop: true,
    lang: "zh-cn",
    screenshot: true,
    hotkey: true,
    preload: "auto",
    volume: 0.7,
    mutex: true,
    playbackSpeed: [0.5, 0.75, 1, 1.25, 1.5, 2],
    video: {
      url: vodUrl.value,
      type: "customHls",
      customType: {
        customHls: (video, player) => {
          const hls = new Hls();
          hls.loadSource(video.src);
          hls.attachMedia(video);
        },
      },
    },
    danmaku: {
      id: props.vodId + "_" + props.episode,
      api: danmuApiUrl.value,
      token: token.value.length > 0 ? token.value : "",
      user: userId.value.length > 0 ? userId.value : "",
      maximum: 1000,
      bottom: "15%",
      unlimited: true,
      speedRate: 0.3,
    },
  });
  //当浏览器可以开始播放视频时触发，但可能只有一部分视频已经下载。
  dp.on("canplay", function () {
    //实时绘制一个新弹幕
    dp.danmaku.draw({
      text: "快来发个弹幕吧！！！（づ￣3￣）づ╭❤️～",
      color: "#fd5c43",
      type: 0,
    });
    setTimeout(() => {
      dp.danmaku.draw({
        text: "快来发个弹幕吧！！！（づ￣3￣）づ╭❤️～",
        color: "#fd5c43",
        type: 0,
      });
    }, 300000);
  });
  //在视频播放过程中，定时触发以报告当前播放时间。
  dp.on("timeupdate", function () {
    localStorage.setItem(
      props.vodId + "_" + props.episode + "_hasBeenPlayedTime",
      dp.video.currentTime
    );
  });
}

//监听又父组件传过来的视频地址
watchEffect(() => {
  // show.value = true;
  vodUrl.value = props.url;
  //初始化播放时间
  seekTime.value = Number(
    localStorage.getItem(
      props.vodId + "_" + props.episode + "_hasBeenPlayedTime"
    ) || 0
  );

  if (dp) {
    // show.value = false;
    //切换视频
    dp.switchVideo(
      {
        url: vodUrl.value,
        type: "customHls",
        customType: {
          customHls: (video, player) => {
            const hls = new Hls();
            hls.loadSource(video.src);
            hls.attachMedia(video);
          },
        },
      },
      {
        id: props.vodId + "_" + props.episode,
        api: danmuApiUrl.value,
        token: token.value.length > 0 ? token.value : "",
        user: userId.value.length > 0 ? userId.value : "",
        maximum: 1000,
        bottom: "15%",
        unlimited: true,
        speedRate: 0.3,
      }
    );

    //跳到指定时间
    dp.seek(seekTime.value);
  }
});
</script>

<style scoped>
.video {
  height: 100%;
  width: 100%;
}
.dplayer {
  width: 100%;
  height: 85vh;
}
/* 禁用视频右键菜单 */
:deep(.dplayer-menu, .dplayer-mask) {
  display: none !important;
}
.controlStrip {
  width: 99%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 5px;
  background-color: #242e37;
}

@media screen and (max-width: 1000px) {
  .dplayer {
    height: 35vh;
  }
}
</style>